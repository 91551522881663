//
// this template is unused. it's just here
// to satisfy gatsby-node.js
//
// instead go to pages/latest_news/index.js
//
import React from 'react';
const LatestNewsTemplatePage = ({}) => {
  return <div></div>;
};

export default LatestNewsTemplatePage;
